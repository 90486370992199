import Image from 'next/image';
import Link from 'next/link';
import customLoader from '../../../helpers/custom-image-loader';
import BrandConfigStore from '../../../context/store/brand-config';
import ConstantDataStore from '../../../context/store/constant-data';

export default function Logo(p) {
	const { themeNumber } = p;
	const { backgroundColor, colors } = BrandConfigStore(state => state.config.themeConfig.container);
	const { providers } = ConstantDataStore(state => ({ providers: state.providers }));

	const themeProps = {
		theme1: {
			imageContainer: {
				className: 'border-2 provider-width rounded-lg shrink-0 cursor-pointer p-4',
				style: {
					borderColor: backgroundColor.secondary
				},
			},
		},
		theme2: {
			imageContainer: {
				className: 'provider-width rounded-tr-3xl rounded-bl-3xl shrink-0 cursor-pointer p-4 border shadowCard',
				style: {
					borderColor: colors.primary,
					background: backgroundColor.tertiary,
					backgroundImage: `linear-gradient(to bottom right, ${backgroundColor.primary}, ${backgroundColor.tertiary})`
				},
			},
		},
		theme3: {
			imageContainer: {
				className: 'provider-width shrink-0 cursor-pointer p-4',
				style: {
					background: backgroundColor.tertiary,
				},
			},
		},
		container: {
			className: 'flex overflow-x-scroll scrollbar-hide gap-4',
		},
		subImageContainer: {
			className: 'h-full relative w-full flex justify-center items-center',
		},
		image: {
			className: 'object-contain'
		// 	objectFit: 'contain',
		// 	layout: 'fill',
		// 	loader: customLoader,
		}
	};

	const theme = themeProps[`theme${themeNumber}`];


	return (
		<div {...themeProps.container}>
			{providers.length > 0 ? providers?.slice(0, 10).map((gp) => {
				return (
					<Link href="/providers/" key={gp.gpid}>
						<div {...theme.imageContainer}>
							<div {...themeProps.subImageContainer}>
								{gp.gplogo ? (
									<Image
										objectFit="contain"
										layout="fill"
										loader={customLoader}
										src={gp.gplogo ?? ''}
									/>
								) : <p className="text-2xl" style={{ color: colors.primary }}>{gp.gpname}</p>}
							</div>
						</div>
					</Link>
				);
			}) : (
				<div className="flex gap-4 overflow-hidden">
					{
						Array.from({ length: 9 }).map((_elm, i) => (
							<div key={i} className="h-20 w-40 bg-gray-500/30 animate-pulse flex-none"></div>
						))
					}
				</div>
			)}
		</div>
	);
}

