import { useRouter } from 'next/router';
import GlobalStateStore from '../../../../context/store/global-state';
import ModalStore from '../../../../context/store/modal-pop-up';
import { twMerge } from 'tailwind-merge';
import { useContext, useEffect, useMemo, useState } from 'react';
import AppContext from '../../../../context/app.context/app.context';
import TransactionServices from '../../../../services/api/transaction/transaction';
import { winningNumbersSectionProps } from '../../../../resources/themes/winning-numbers';
import BrandConfigStore from '../../../../context/store/brand-config';
import { formatDate } from '../../../../helpers/time-helper';

const TogelCard = (p) => {
	const token = GlobalStateStore(state => state.token);
	const setModal = ModalStore(state => state.setModal);
	const brandId = BrandConfigStore(state => state.config.brandId);

	const router = useRouter();
	const appContext = useContext(AppContext);

	const [data, setData] = useState([]);
	
	const { themeNumber } = p;

	const getLotteryRoomList = async () => {
		const payload = {
			systemType: 'getListLottery',
			brandId: brandId,
		};

		try {
			const response = await TransactionServices.GameTransactionNoAuth(payload);
			const sortedData = response.data.sort((a, b) => a.order > b.order ? 1 : -1);
			setData(sortedData);
		} catch (error) {
			console.log(error);
		}
	};

	const clickHandler = () => {
		if (!token || token.split(':')[0].toLowerCase() === 'sessiontoken') {
			setModal({ type: 'login', mode: 'login' });
			return;
		} 
		if (token) {
			setModal({ type: 'HKB-1', mode: 'topup' });
		}
	};

	const regionClickHandler = (id, room) => {
		router.push(`/winning-numbers/?room_id=${id}&room_name=${room}`);
	};

	const refinedData = useMemo(() => {
		const topData = data.slice(0, Math.ceil(data.length/2));
		const bottomData = data.slice(Math.ceil(data.length/2));

		return { topData, bottomData };
	}, [data]);

	useEffect(() => {
		if (token && token !== '') {
			getLotteryRoomList();
		}
	}, [token]);

	

	const theme = winningNumbersSectionProps[`theme${themeNumber}`];

	return (
		<div className="overflow-x-auto overflow-y-hidden scrollbar-hide">
			<div className="space-y-4">
				<div { ...theme.sectionContainerProps } >
					<div className={twMerge('hidden lg:block', theme.cardContainerProps.className)}>
						<button onClick={clickHandler} { ...theme.buttonProps }>{appContext.t('btn.placeNumbers')}</button>
					</div>
					{refinedData.topData.length > 0 ? (
						refinedData.topData.map((d) => (
							<button onClick={() => regionClickHandler(d.gpGameId, d.title)} key={d.gpGameId} style={{  }} { ...theme.cardContainerProps }>
								{/* <img src={d.image} className="absolute w-full h-full" alt={d.title} style={{ opacity: '.4', zIndex: -1 }} /> */}
								<div className="z-10">
									<p { ...theme.headerProps } >{d.title}</p>
									<div { ...theme.contentContainerProps } >
										<div className="w-full h-10 p-2">
											<img src={d.image} className="mx-auto h-10 object-contain" alt="" />
										</div>
										<p { ...theme.contentProps } >{d.number[0]}</p>
										<p { ...theme.dateProps } >{formatDate(d.date, 'DD/MMM/YYYY')}</p>
									</div>
								</div>
							</button>
						))
					) : (
						Array.from({ length: 7 }).map((_, i) => (
							<div key={i} { ...theme.cardContainerLoaderProps }/>
						))
					)}
				</div>

				<div { ...theme.sectionContainerProps } >
					{refinedData.bottomData.length > 0 ? (
						refinedData.bottomData.map((d) => (
							<button onClick={() => regionClickHandler(d.gpGameId, d.title)} key={d.gpGameId} { ...theme.cardContainerProps }>
								{/* <img src={d.image} className="absolute w-full h-full" alt={d.title} style={{ opacity: '.4', zIndex: -1 }} /> */}
								<div className="z-10">
									<p { ...theme.headerProps } >{d.title}</p>
									<div { ...theme.contentContainerProps } >
										<div className="w-full h-10 p-2">
											<img src={d.image} className="mx-auto h-10 object-contain" alt="" />
										</div>
										<p { ...theme.contentProps } >{d.number[0]}</p>
										<p { ...theme.dateProps } >{formatDate(d.date, 'DD/MMM/YYYY')}</p>
									</div>
								</div>
							</button>
						))
					) : (
						Array.from({ length: 8 }).map((_, i) => (
							<div key={i} { ...theme.cardContainerLoaderProps }/>
						))
					)}
				</div>
			</div>
		</div>
	);
};

export default TogelCard;
