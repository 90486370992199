const SubscribeTransaction = `
  subscription MySubscription ($brandId: String!, $transactionType: TransactionType) {
    depoWdNotif(brandId: $brandId, transactionType: $transactionType) {
      amount
      currency
      currencyLogo
      username
    }
  }
`;

const TransactionSubscription = { SubscribeTransaction };

export default TransactionSubscription;
