import { useState, useEffect } from 'react';
import GameThumbnail from '../GameThumbnail/GameThumbnail';
import GlobalStateStore from '../../../context/store/global-state';
import TransactionServices from '../../../services/api/transaction/transaction';
import BrandConfigStore from '../../../context/store/brand-config';

const Game = (p) => {
	const { category, themeNumber, setTotalGames } = p;
	const { token, setIsRendered } = GlobalStateStore((state) => ({
		setIsRendered: state.setIsRendered,
		token: state.token
	}));
	const config = BrandConfigStore((state) => state.config);
	const [loading, setLoading] = useState(true);
	const [gameList, setGameList] = useState([]);

	const { brandId } = config;

	useEffect(() => {
		if (token && token !== '') {
			TransactionServices.GameTransactionNoAuth({
				systemType: 'getHomeCategoryGame',
				brandId: brandId,
				gameCategory: category
			})
				.then((response) => {
					const data = response.data;
					setGameList([...data[category].games]);
					setLoading(false);
					setTotalGames((prev) => ({ ...prev, [category]: data[category].games.length }));
				})
				.catch((err) => {
					console.log(err);
				})
				.finally(() => {
					setIsRendered(true);
				});
		}
	}, [token]);

	if (!gameList.length || loading) {
		return (
			<div className="flex scrollbar-hide overflow-hidden flex-col gap-6 max-h-fit">
				<GamesRowLoading n={10} />
				<GamesRowLoading n={10} />
			</div>
		);
	}

	return (
		<div className="overflow-x-scroll scrollbar-hide overflow-y-hidden">
			<div className="space-y-4">
				{gameList.length > 10 ? (
					<>
						<GamesRow games={gameList} n={0} theme={themeNumber} />
						<GamesRow games={gameList} n={1} theme={themeNumber} />
					</>
				) : (
					<GamesRow games={gameList} theme={themeNumber} />
				)}
			</div>
		</div>
	);
};

const GamesRow = ({ games = [], theme = 1, n }) => {
	return (
		<div className={`grid gap-4 home-game-section${theme}`}>
			{games
				.filter((_unit, index) => (n === 0 || n === 1 ? index % 2 === n : true))
				.map((game) => (
					<GameThumbnail key={game.gameId} themeNumber={theme} game={game} />
				))}
		</div>
	);
};

const GamesRowLoading = ({ n = 2 }) => (
	<div className="flex gap-7">
		{[...Array(n).keys()].map((el) => (
			<div key={el} className="w-44 h-44 bg-gray-600 animate-pulse rounded-lg shrink-0"></div>
		))}
	</div>
);

export default Game;
