import { Fragment, useContext } from 'react';
import CurrencyFormatHelper from '../../../helpers/currency-format.helper';
import BrandConfigStore from '../../../context/store/brand-config';
import AppContext from '../../../context/app.context/app.context';
function RealTimeTable(p) {
	const { backgroundColor, colors } = BrandConfigStore(state => state.config.themeConfig.container);
	const appContext = useContext(AppContext);
	const { data } = p;

	return (
		<Fragment>
			<table className="w-full table-fixed rounded-sm text-sm lg:text-lg "
				style={{
					background: backgroundColor.secondary
				}}
			>
				<thead className=" w-full h-7 font-quicksandRegular">
					<tr className="">
						<th className="px-1 sm:px-2 py-2 text-start">{appContext.t('thead.user')}</th>
						<th className="px-1 sm:px-2 py-2 text-start">{appContext.t('thead.amount')}</th>
					</tr>
				</thead>

				<tbody className="font-thin">
					{data.map((item, index) => (
						<tr key={index} className=''
							style={{
								background: index % 2 === 0 ? backgroundColor.accent : backgroundColor.secondary,
								color: colors.primary,
								borderTopColor:backgroundColor.primary
							}}
						>
							<td className="px-1 sm:px-2 py-1">
								{item.username}
							</td>
							<td className="px-1 sm:px-2 py-1 flex items-center justify-start relative gap-1 min-w-24 tablet:min-w-28">
								<img
									className="w-5 h-5"
									src={item.currencyLogo}
									alt="currency logo"
								/>
								<p>{CurrencyFormatHelper.currency(item.amount)}</p>
							</td>
						</tr>
					))}
				</tbody>
			</table>

		</Fragment>
	);
}
export default RealTimeTable;
