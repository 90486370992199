import { useEffect, useState } from 'react';
import GameThumbnail from '../GameThumbnail/GameThumbnail';
import TransactionServices from '../../../services/api/transaction/transaction';
import GlobalStateStore from '../../../context/store/global-state';
import BrandConfigStore from '../../../context/store/brand-config';
// import RecommendCard from './RecommendCard';

function Recommend(p) {
	const { themeNumber } = p;
	const token = GlobalStateStore((state) => state.token);
	const brand = BrandConfigStore((state) => state.config.brandId);

	const [games, setGames] = useState([]);

	const getGames = async () => {
		const payload = {
			systemType: 'getRecommendedGame',
			brandId: brand
		};

		try {
			const response = await TransactionServices.GameTransactionNoAuth(payload);

			setGames(response.data.slice(0, 10));
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		if (token && token !== '') {
			getGames();
		}
	}, [token]);

	const theme = {
		container: {
			className: 'flex flex-col mx-auto space-y-1 tablet:space-y-2'
		},
		subContainer: {
			className: 'overflow-x-scroll scrollbar-hide'
		},
		childrenContainer: {
			className: `grid gap-4 home-game-section${themeNumber}`
		}
	};

	return (
		<div {...theme.container}>
			<div {...theme.subContainer}>
				<div {...theme.childrenContainer}>
					{games.length
						? games.map((item) => (
							<GameThumbnail key={item.gameName} themeNumber={themeNumber} game={item} />
						))
						: Array.from({ length: 10 }).map((_, i) => (
							<div key={i} className="h-40 w-full bg-gray-500/40 animate-pulse col-span-1" />
						))}
				</div>
			</div>
		</div>
	);
}

export default Recommend;
