import brandSettings from '../../../public/brandSettings.json';

const { backgroundColor, colors } = brandSettings.themeConfig.container;

export const winningNumbersSectionProps = {
	theme1: {
		sectionContainerProps: {
			// className: 'grid grid-cols-2 mobile-xl:grid-cols-4 tablet:grid-cols-6 laptop-l:grid-cols-8 gap-4',
			className: 'flex gap-4',
		},
		buttonProps: {
			className: 'btn rounded-sm w-full',
			style: { backgroundColor: backgroundColor.accent2, color: colors.primary, height: '100%' }
		},
		cardContainerProps: {
			// className: 'space-y-1 col-span-1 rounded-lg overflow-hidden',
			className: 'card-width-3 gameCard overflow-hidden rounded-lg relative',
			style: { backgroundColor: backgroundColor.accent },
		},
		cardContainerLoaderProps: {
			className: 'card-width gameCard rounded-lg overflow-hidden bg-gray-500/30 animate-pulse',
			style: { minHeight: '5rem' }
		},
		headerProps: {
			className: 'text-center text-base py-1 absolute top-0 w-full',
			style: { backgroundColor: backgroundColor.accent2 }
		},
		contentContainerProps: {
			className: 'space-y-2 pt-10',
		},
		contentProps: {
			className: 'text-xl sm:text-3xl text-center p-2 tracking-widest font-bold',
		},
		dateProps: {
			className: 'text-center text-xxs sm:text-xs pb-2'
		}
	},
	theme2: {
		sectionContainerProps: {
			// className: 'grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4',
			className: 'flex gap-4',
		},
		buttonProps: {
			className: 'btn btn-block rounded-sm',
			style: { backgroundColor: backgroundColor.accent2, color: colors.primary, height: '100%' }
		},
		cardContainerProps: {
			// className: 'space-y-1 border shadowCard col-span-1 rounded-tr-2xl rounded-bl-2xl overflow-hidden',
			className: 'card-width-2 gameCard overflow-hidden rounded-tr-3xl border rounded-bl-3xl relaitve',
			style: { backgroundColor: backgroundColor.accent },
		},
		cardContainerLoaderProps: {
			className: 'card-width-2 gameCard rounded-tr-3xl border rounded-bl-3xl overflow-hidden bg-gray-500/30 animate-pulse',
			style: { minHeight: '8rem' }
		},
		headerProps: {
			className: 'text-center text-base py-1',
			style: { backgroundColor: backgroundColor.accent2 }
		},
		contentContainerProps: {
			className: 'space-y-2',
		},
		contentProps: {
			className: 'text-3xl text-center p-2 tracking-widest font-bold',
		},
		dateProps: {
			className: 'text-center text-xs pb-2'
		}
	},
	theme3: {
		sectionContainerProps: {
			// className: 'grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 gap-4',
			className: 'flex gap-4',
		},
		buttonProps: {
			className: 'btn btn-block rounded-sm',
			style: { backgroundColor: backgroundColor.accent2, color: colors.primary, height: '100%' }
		},
		cardContainerProps: {
			// className: 'space-y-1 col-span-1 rounded-sm overflow-hidden',
			className: 'card-width-3 gameCard rounded-sm overflow-hidden relative',
			style: { backgroundColor: backgroundColor.accent },
		},
		cardContainerLoaderProps: {
			className: 'card-width-3 gameCard rounded-sm overflow-hidden bg-gray-500/30 animate-pulse',
			style: { minHeight: '8rem' }
		},
		headerProps: {
			className: 'text-center text-base py-1',
			style: { backgroundColor: backgroundColor.accent2, position: 'relative', top: 0 },
		},
		contentContainerProps: {
			className: 'space-y-2',
		},
		contentProps: {
			className: 'text-3xl text-center p-2 tracking-widest font-bold',
		},
		dateProps: {
			className: 'text-center text-xs pb-2'
		}
	},
};
