import { useContext } from 'react';
import AppContext from '../../../context/app.context/app.context';
import BrandConfigStore from '../../../context/store/brand-config';
import ModalStore from '../../../context/store/modal-pop-up';

export default function LastPlayedGame(p) {
	const { backgroundColor, colors } = BrandConfigStore(
		(state) => state.config.themeConfig.container
	);
	const { setModal } = ModalStore((state) => ({ setModal: state.setModal }));
	const { lastPlayedGameData, themeNumber } = p;
	const appContext = useContext(AppContext);

	const lastPlayedText = () => {
		return (
			<div className="flex flex-col-reverse lg:flex-col ">
				<p className="text-xs mobile-m:text-xs mobile-l:text-base mobile-xl:text-base sm:text-xl md:text-2xl lg:text-lg font-quicksandLight uppercase tracking-wider truncate">
					{lastPlayedGameData?.show
						? appContext.t('text.lastPlayed')
						: lastPlayedGameData?.data?.title}
				</p>
				<div
					className="h-px"
					style={{
						background: backgroundColor.accent2,
						opacity: 0.5
					}}></div>
			</div>
		);
	};
	const handleClick = () => {
		if (lastPlayedGameData?.show) {
			window.open(
				`/play/?gpId=${lastPlayedGameData?.data?.gpId}&gameId=${lastPlayedGameData?.data?.gpGameId}`
			);
		} else {
			setModal({ type: 'login', mode: 'login' });
		}
	};

	const themeProps = {
		theme1: {
			container: {
				onClick: handleClick,
				className:
					'rounded-lg w-full lg:w-full lg:h-1/2 aspect-[3/1] lg:aspect-auto flex items-center relative overflow-hidden cursor-pointer',
				style: {
					backgroundImage: `linear-gradient(to top left, ${backgroundColor.accent2}, ${backgroundColor.primary})`,
					color: colors.primary
				}
			},
			content1: {
				className: 'absolute h-10 w-[50rem] lg:w-96 translate-y-0 -rotate-45 -right-0 xl:right-20',
				style: { background: backgroundColor.accent2, opacity: 0.2 }
			},
			content2: {
				className: 'absolute h-10 w-[50rem] lg:w-96 translate-y-0 -rotate-45 -right-40  xl:left-20',
				style: { background: backgroundColor.accent2, opacity: 0.2 }
			},
			content3: {
				parentContainer: {
					className: 'flex w-full h-full z-10 px-4 items-center justify-center flex-col gap-4'
				},
				container: {
					className: 'lg:space-y-1 w-full'
				},
				lastPlayedText: {
					className: 'hidden lg:block'
				},
				contentContainer: {
					className: 'items-center gap-4 w-full grid grid-cols-3'
				},
				subContent1: {
					className:
						'order-last lg:order-first rounded-lg col-span-1 relative opacity-30 mx-auto mobile-xl:w-full w-full lg:opacity-100 overflow-hidden'
				},
				subContent2: {
					className: 'w-full h-full flex items-center col-span-2 space-y-px z-10'
				},
				subContent2Container: {
					className: 'w-full'
				},
				subContent1Image: {
					src: lastPlayedGameData?.data?.s3ImageUrl?.square200x200,
					alt: lastPlayedGameData?.data?.gameName,
					className: 'w-full rounded-lg scale-150 lg:scale-100'
				},
				gameNameText: {
					className:
						'text-start font-quicksandRegular text-2xl mobile-m:text-4xl mobile-l:text-5xl mobile-xl:text-2xl sm:text-3xl md:text-4xl lg:text-3xl capitalize truncate'
				},
				gpNameText: {
					className: 'text-start font-quicksandLight lg:flex lg:text-lg'
				}
			}
		},
		theme2: {
			container: {
				onClick: handleClick,
				className:
					'w-full lg:w-full lg:h-1/2 aspect-2/1 mobile-xl:aspect-[3/1] lg:aspect-auto flex items-center relative overflow-hidden cursor-pointer border shadowCard rounded-tr-3xl rounded-bl-3xl',
				style: {
					backgroundImage: `linear-gradient(to top left, ${backgroundColor.tertiary}, ${backgroundColor.primary})`,
					color: colors.primary
				}
			},
			content1: {
				className: 'absolute h-10 w-[50rem] lg:w-96 translate-y-0 -rotate-45 -right-0 xl:right-20',
				style: { background: backgroundColor.accent2, opacity: 0.2 }
			},
			content2: {
				className: 'absolute h-10 w-[50rem] lg:w-96 translate-y-0 -rotate-45 -right-40 xl:left-20',
				style: { background: backgroundColor.accent2, opacity: 0.2 }
			},
			content3: {
				parentContainer: {
					className:
						'flex w-full h-full z-10 px-0 md:px-4 items-center justify-center flex-col gap-4'
				},
				container: {
					className: 'lg:space-y-1 w-full'
				},
				lastPlayedText: {
					className: 'hidden lg:block'
				},
				contentContainer: {
					className: 'items-center gap-4 w-full grid grid-cols-3'
				},
				subContent1: {
					className:
						'rounded-lg w-full md:col-span-1 absolute md:relative opacity-10 md:opacity-100'
				},
				subContent2: {
					className:
						'"w-full h-full flex items-center md:col-span-2 space-y-px z-10 lg:px-0 col-span-3'
				},
				subContent2Container: {
					className: 'w-full px-4 lg:px-0'
				},
				subContent1Image: {
					src: lastPlayedGameData?.data?.s3ImageUrl?.square200x200,
					alt: lastPlayedGameData?.data?.gameName,
					className: 'w-full rounded-lg scale-150 lg:scale-100'
				},
				gameNameText: {
					className:
						'text-start font-quicksandRegular text-lg mobile-m:text-2xl mobile-l:text-2xl mobile-xl:text-3xl sm:text-4xl md:text-4xl lg:text-3xl capitalize truncate'
				},
				gpNameText: {
					className: 'text-start font-quicksandLight hidden lg:flex lg:text-lg'
				}
			}
		},
		theme3: {
			container: {
				onClick: handleClick,
				className:
					'w-full  sm:aspect-[6/1] aspect-[6/2] h-full flex items-center relative overflow-hidden cursor-pointer rounded',
				style: {
					backgroundImage: `linear-gradient(to top left, ${backgroundColor.tertiary}, ${backgroundColor.primary})`,
					color: colors.primary
				}
			},
			image1: {
				src: lastPlayedGameData?.data?.s3ImageUrl?.square200x200,
				alt: lastPlayedGameData?.data?.gameName,
				className: 'rounded-lg aspect-square absolute w-1/5 opacity-20 rotate-45 left-40 top-20'
			},
			image2: {
				src: lastPlayedGameData?.data?.s3ImageUrl?.square200x200,
				alt: lastPlayedGameData?.data?.gameName,
				className: 'rounded-lg aspect-square absolute w-1/5 opacity-20 rotate-45 left-20 top-10'
			},
			image3: {
				src: lastPlayedGameData?.data?.s3ImageUrl?.square200x200,
				alt: lastPlayedGameData?.data?.gameName,
				className: 'rounded-lg aspect-square absolute w-1/5 opacity-20 rotate-45'
			},
			content3: {
				parentContainer: {
					className:
						'flex w-full h-full z-10 px-0 md:px-4 items-center justify-center flex-col gap-4'
				},
				container: {
					className: 'w-full h-full flex items-center'
				},
				contentContainer: {
					className: 'w-full px-2 md:px-4 justify-end flex items-end gap-4'
				},
				text1: {
					className:
						'text-xs mobile-m:text-xs mobile-l:text-xs mobile-xl:text-base sm:text-xl md:text-2xl lg:text-4xl xl:text-lg font-quicksandRegular xl:font-quicksandLight uppercase tracking-wider truncate'
				},
				text2: {
					className:
						'text-start font-quicksandRegular hidden xl:block text-lg mobile-m:text-2xl mobile-l:text-2xl mobile-xl:text-3xl sm:text-4xl md:text-4xl lg:text-5xl capitalize truncate'
				}
			}
		}
	};

	const theme = themeProps[`theme${themeNumber}`];

	return (
		<div {...theme.container}>
			{themeNumber === 3 ? (
				<>
					<img {...theme.image1} />
					<img {...theme.image2} />
					<img {...theme.image3} />
				</>
			) : (
				<>
					<div {...theme.content1} />
					<div {...theme.content2} />
				</>
			)}
			<div {...theme.content3.parentContainer}>
				<div {...theme.content3.container}>
					{themeNumber !== 3 ? (
						<>
							<div {...theme.content3.lastPlayedText}>{lastPlayedText()}</div>
							<div {...theme.content3.contentContainer}>
								<div {...theme.content3.subContent1}>
									<img {...theme.content3.subContent1Image} />
								</div>

								<div {...theme.content3.subContent2}>
									<div {...theme.content3.subContent2Container}>
										<p {...theme.content3.gameNameText}>{lastPlayedGameData?.data?.gameName}</p>
										<p {...theme.content3.gpNameText}>{lastPlayedGameData?.data?.gpName}</p>
										<div className="block lg:hidden">{lastPlayedText()}</div>
									</div>
								</div>
							</div>
						</>
					) : (
						<div {...theme.content3.contentContainer}>
							<p {...theme.content3.text1}>
								{lastPlayedGameData?.show
									? appContext.t('text.lastPlayed')
									: lastPlayedGameData?.data?.title}
							</p>
							<p {...theme.content3.text2}>{lastPlayedGameData?.data?.gameName}</p>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
