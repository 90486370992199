import { useState, useEffect, useContext } from 'react';
import { API } from 'aws-amplify';
import TransactionSubscription from '../../../graphql/subscriptions/transaction';
import RealTimeTable from './RealTimeTable';
import TransactionQueries from '../../../graphql/queries/transaction';
import BrandConfigStore from '../../../context/store/brand-config';
import AppContext from '../../../context/app.context/app.context';

const RealTimeTransactions = (p) => {
	const { themeNumber } = p;
	const { themeConfig, brandId } = BrandConfigStore(state => state.config);
	const appContext = useContext(AppContext);
	const { backgroundColor, colors } = themeConfig.container;
	const [transactionType, setTransactionType] = useState('deposit');
	const [depoWdData, setDepoWdData] = useState([]);

	useEffect(() => {
		let tmpDepoWd = [];

		// Get All Depo and WD
		API.graphql({
			query: TransactionQueries.GetDepoWd,
			variables: {
				brandId: brandId
			},
			authToken: process.env.NEXT_PUBLIC_AWS_APPSYNC_APIKEY
		}).then(res => {
			tmpDepoWd = [...res.data.getDepoWd.report];
			setDepoWdData([...res.data.getDepoWd.report]);
		});

		// Subscribe to Depo & WD
		API.graphql({
			query: TransactionSubscription.SubscribeTransaction,
			variables: {
				brandId: brandId
			},
			authToken: process.env.NEXT_PUBLIC_AWS_APPSYNC_APIKEY
		}).subscribe({
			next: res => {
				const { depoWdNotif } = res.value.data;
				depoWdData.push(depoWdNotif);
				setDepoWdData([ ...depoWdData, ...tmpDepoWd ]);
			}
		});
	}, [brandId]);

	const themeProps = {
		theme1: {
			container: {
				className: 'w-full rounded-lg h-auto p-4 space-y-4',
				style: {
					background: backgroundColor.tertiary
				},
			},
			buttonContainer: {
				className: 'flex bg-inherit space-x-2 text-sm sm:text-base',
			},
			button1: {
				className: 'w-44 h-9',
				onClick: () => setTransactionType('deposit'),
				style: {
					backgroundColor: transactionType === 'deposit' ? backgroundColor.secondary : 'transparent',
					color: transactionType === 'deposit' ? colors.primary : colors.secondary,
					border: transactionType === 'deposit' ? 'none' : `1px solid  ${backgroundColor.primary}`,						
				},
			},
			button2: {
				className: 'w-44 h-9',
				onClick: ()=>setTransactionType('withdrawal'),
				style: {
					backgroundColor: transactionType === 'withdrawal' ? backgroundColor.secondary : 'transparent',
					color: transactionType === 'withdrawal' ? colors.primary : colors.secondary,
					border: transactionType === 'withdrawal' ? 'none' : `1px solid  ${backgroundColor.primary}`,						
				},
			},
			dataContainer: {
				className: 'w-full max-h-40 tablet:max-h-60 laptop:max-h-72 scrollbar-hide overflow-y-scroll',
			},
			data: {
				depositData: depoWdData.filter(t => t.transactionType === 'DEPOSIT'),
				withdrawalData: depoWdData.filter(t => t.transactionType === 'WITHDRAW'),
			}
		},
		theme2: {
			container: {
				className: 'w-full rounded-lg h-auto p-4 space-y-4',
				style: {
					background: backgroundColor.tertiary,
					backgroundImage: `linear-gradient(to bottom right, ${backgroundColor.primary}, ${backgroundColor.tertiary})`
				},
			},
			buttonContainer: {
				className: 'flex bg-inherit space-x-2 text-sm sm:text-base',
			},
			button1: {
				className: 'w-44 h-9 border shadowCard',
				onClick: () => setTransactionType('deposit'),
				style: {
					backgroundColor: transactionType === 'deposit' ? backgroundColor.secondary : 'transparent',
					color: transactionType === 'deposit' ? colors.primary : colors.secondary,
					border: transactionType === 'deposit' ? 'none' : `1px solid  ${colors.primary}`,						
				},
			},
			button2: {
				className: 'w-44 h-9 border shadowCard',
				onClick: ()=>setTransactionType('withdrawal'),
				style: {
					backgroundColor: transactionType === 'withdrawal' ? backgroundColor.secondary : 'transparent',
					color: transactionType === 'withdrawal' ? colors.primary : colors.secondary,
					border: transactionType === 'withdrawal' ? 'none' : `1px solid  ${colors.primary}`,						
				},
			},
			dataContainer: {
				className: 'w-full max-h-40 tablet:max-h-60 laptop:max-h-72 scrollbar-hide overflow-y-scroll',
			},
			data: {
				depositData: depoWdData.filter(t => t.transactionType === 'DEPOSIT'),
				withdrawalData: depoWdData.filter(t => t.transactionType === 'WITHDRAW'),
			}
		},
		theme3: {
			container: {
				className: 'w-full rounded h-auto px-8 py-10 flex flex-col items-end space-y-4',
				style: {
					background: backgroundColor.tertiary,
				},
			},
			buttonContainer: {
				className: 'flex w-full md:w-fit bg-inherit space-x-2 text-sm sm:text-base',
			},
			button1: {
				className: 'w-full px-3 py-2 whitespace-nowrap',
				onClick: () => setTransactionType('deposit'),
				style: {
					backgroundColor: transactionType === 'deposit' ? backgroundColor.secondary : 'inherit',
					color: transactionType === 'deposit' ? colors.primary : colors.secondary,
					border: transactionType === 'deposit' ? 'none' : `1px solid  ${backgroundColor.primary}`,						
				},
			},
			button2: {
				className: 'w-full px-3 py-2 whitespace-nowrap',
				onClick: ()=>setTransactionType('withdrawal'),
				style: {
					backgroundColor: transactionType === 'withdrawal' ? backgroundColor.secondary : 'inherit',
					color: transactionType === 'withdrawal' ? colors.primary : colors.secondary,
					border: transactionType === 'withdrawal' ? 'none' : `1px solid  ${backgroundColor.primary}`,						
				},
			},
			dataContainer: {
				className: 'w-full max-h-40 tablet:max-h-60 laptop:max-h-72 scrollbar-hide overflow-y-scroll',
			},
			data: {
				depositData: depoWdData.filter(t => t.transactionType === 'DEPOSIT'),
				withdrawalData: depoWdData.filter(t => t.transactionType === 'WITHDRAW'),
			}
		},
	};

	const theme = themeProps[`theme${themeNumber}`];

	// console.log(theme.data[`${transactionType}Data`])
	
	return (
		<div {...theme.container}>
			<div {...theme.buttonContainer}>
				<button {...theme.button1} >{appContext.t('btn.lastDeposit')}</button>
				<button {...theme.button2}>{appContext.t('btn.lastWithdrawal')}</button>
			</div>
			<div {...theme.dataContainer}>
				<RealTimeTable data={theme.data[`${transactionType}Data`]} />
			</div>
		</div>
	);
};

export default RealTimeTransactions;
