import { useContext } from 'react';
import SectionLayout from '../../templates/Layout/SectionLayout';
import GameThumbnailRect from '../GameThumbnail/GameThumbnailRect';
import AppContext from '../../../context/app.context/app.context';

const GameLarge = (p) => {
	const appContext = useContext(AppContext);
	const data = [
		{
			title: 'SBO',
			summary: appContext.t('text.sportSummary'),
			imageUrl: '/images/sports/football-player-1.png',
			url: '/play/?gpId=sbo&gameId=1'
		},
		{
			title: 'SABA',
			summary: appContext.t('text.sportSummary'),
			imageUrl: '/images/sports/football-player-2.png',
			url: '/play/?gpId=saba&gameId=1'
		}
	];

	return (
		<SectionLayout {...p} name={appContext.t('text.sports')}>
			<div className="cursor-pointer grid grid-cols-1 sm:grid-cols-2 gap-4">
				{data.map((game, index) => {
					return <GameThumbnailRect key={index} data={game} {...p} />;
				})}
			</div>
		</SectionLayout>
	);
};

export default GameLarge;
