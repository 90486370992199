import { useRouter } from 'next/router';
import BrandConfigStore from '../../../context/store/brand-config';

export default function HighlightPromo(p) {
	const { src, context, clickSrc, themeNumber } = p;
	const { backgroundColor, colors } = BrandConfigStore(state => state.config.themeConfig.container);
	const router = useRouter();

	const handleClick = () => {
		router.push(clickSrc);
	};

	const themeProps = {
		theme1: {
			container: {
				onClick: handleClick,
				className: 'rounded-lg w-full lg:w-full lg:h-1/2 aspect-[3/1] lg:aspect-auto flex items-center relative overflow-hidden cursor-pointer',
				style: {
					backgroundImage: `linear-gradient(to bottom right, ${backgroundColor.primary}, ${backgroundColor.accent2})`,
					color: colors.primary
				},
			},
			content1: {
				className: 'absolute h-10 w-[50rem] lg:w-96 translate-y-0 -rotate-45 -right-0 xl:right-20',
				style: { background: backgroundColor.accent2, opacity: 0.2 },
			},
			content2: {
				className: 'absolute h-10 w-[50rem] lg:w-96 translate-y-0 -rotate-45 -right-40  xl:left-20',
				style: { background: backgroundColor.accent2, opacity: 0.2 },
			},
			content3: {
				parentContainer: {
					className: 'flex w-full h-full absolute z-10 px-4 items-center justify-center flex-col gap-4',
				},
				container: {
					className: 'items-center gap-4 lg:gap-2 w-full grid grid-cols-3',
				},
				image: {
					src: src,
					alt: context[0],
					className: 'order-last lg:order-first rounded-lg md:col-span-1 mx-auto mobile-xl:w-full w-full md:relative opacity-30 lg:opacity-100'
				},
				contentContainer: {
					className: 'w-full h-full flex items-center col-span-2 space-y-px z-10',
				},
				contentText: {
					className: 'text-start font-quicksandRegular text-2xl mobile-m:text-3xl mobile-l:text-3xl mobile-xl:text-2xl sm:text-3xl md:text-4xl lg:text-3xl capitalize',
				},
			},
		},
		theme2: {
			container: {
				onClick: handleClick,
				className: 'w-full lg:h-1/2 aspect-[3/1] lg:aspect-auto flex items-center relative overflow-hidden cursor-pointer border shadowCard rounded-tr-3xl rounded-bl-3xl',
				style: {
					backgroundImage: `linear-gradient(to bottom right, ${backgroundColor.primary}, ${backgroundColor.tertiary})`,
					color: colors.primary
				},
			},
			content1: {
				className: 'absolute h-10 w-[50rem] lg:w-96 translate-y-0 -rotate-45 -right-0 xl:right-20',
				style: { background: backgroundColor.accent2, opacity: 0.2 },
			},
			content2: {
				className: 'absolute h-10 w-[50rem] lg:w-96 translate-y-0 -rotate-45 -right-40  xl:left-20',
				style: { background: backgroundColor.accent2, opacity: 0.2 },
			},
			content3: {
				parentContainer: {
					className: 'flex w-full h-full absolute z-10 px-0 md:px-4 items-center justify-center flex-col gap-4',
				},
				container: {
					className: 'items-center gap-4 lg:gap-2 w-full grid grid-cols-3',
				},
				image: {
					src: src,
					alt: context[0],
					className: 'rounded-lg w-full md:col-span-1 absolute md:relative opacity-10 md:opacity-100'
				},
				contentContainer: {
					className: 'w-full px-4 md:px-0 h-full flex items-center md:col-span-2 space-y-px z-10 col-span-3',
				},
				contentText: {
					className: 'text-start font-quicksandRegular text-sm mobile-s:text-base mobile-m:text-lg mobile-l:text-xl mobile-xl:text-xl sm:text-3xl capitalize',
				},
			},
		},
		theme3: {
			container: {
				onClick: handleClick,
				className: 'w-full sm:aspect-[6/1] aspect-[6/2] flex items-center relative overflow-hidden cursor-pointer rounded',
				style: {
					backgroundImage: `linear-gradient(to bottom right, ${backgroundColor.primary}, ${backgroundColor.tertiary})`,
					color: colors.primary
				},
			},
			content3: {
				parentContainer: {
					className: 'flex w-full h-full absolute z-10 px-0 md:px-4 items-center justify-center flex-col gap-4',
				},
				container: {
					className: 'items-center gap-4 lg:gap-2 w-full grid grid-cols-3',
				},
				image2: {
					src: src,
					alt: context[0],
					className: 'rounded-lg aspect-square absolute w-1/5 opacity-20 rotate-45 left-40 top-20'
				},
				image3: {
					src: src,
					alt: context[0],
					className: 'rounded-lg aspect-square absolute w-1/5 opacity-20 rotate-45 left-20 top-10'
				},
				image: {
					src: src,
					alt: context[0],
					className: 'rounded-lg aspect-square absolute w-1/5 opacity-20 rotate-45'
				},
				contentContainer: {
					className: 'w-full h-full flex items-center  space-y-px z-10 col-span-3 px-4 md:px-0',
				},
				contentText: {
					className: 'text-xs mobile-m:text-xs mobile-l:text-xs mobile-xl:text-base sm:text-xl md:text-2xl lg:text-4xl font-quicksandRegular uppercase tracking-wider truncate',
				},
			},
		},
	};

	const theme = themeProps[`theme${themeNumber}`];

	return (
		<div {...theme.container} >
			{themeNumber !== 3 ? (
				<>
					<div {...theme.content1} />
					<div {...theme.content2} />
				</>
			) : null}
			<div {...theme.content3.parentContainer} >
				<div {...theme.content3.container}>
					{themeNumber === 3 ? (
						<>
							<img {...theme.content3.image2} />
							<img {...theme.content3.image3} />
						</>
					) : null}
					<img {...theme.content3.image} />
					<div {...theme.content3.contentContainer} >
						<p {...theme.content3.contentText} >{context[1]}</p>
					</div>
				</div>
			</div>
		</div>
	);
}
