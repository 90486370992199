import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper';
import Image from 'next/image';
import customLoader from '../../../helpers/custom-image-loader';
import { useContext, useEffect, useState } from 'react';
import BrandConfigStore from '../../../context/store/brand-config';
import HighlightPromo from './HighlightPromo';
import LastPlayedGame from './LastPlayedGame';
import AppContext from '../../../context/app.context/app.context';

export default function PromoBanner(p) {
	const { banners, themeConfig } = BrandConfigStore(state => state.config);
	const { backgroundColor, colors } = themeConfig.container;
	const appContext = useContext(AppContext);
	const [bannerList, setBannerList] = useState([]);
	const { themeNumber, lastPlayedGameData } = p;

	const themeProps = {
		theme1: {
			container: null,
			swiperProps: {
				className: 'rounded-lg aspekper w-full h-full cursor-pointer',
				modules: [Autoplay, Pagination],
				autoplay: { delay: 3000 },
				pagination: {
					clickable: true
				},
				lazy: true,
			},
		},
		theme2: {
			container: {
				className: 'p-4 rounded-tr-3xl rounded-bl-3xl border shadowCard aspekper',
				style: {
					borderColor: colors.primary,
					backgroundImage: `linear-gradient(to bottom right, ${backgroundColor.primary}, ${backgroundColor.tertiary})`,
				},
			},
			swiperProps: {
				className:'aspekper rounded-tr-3xl rounded-bl-3xl w-full h-full cursor-pointer',
				modules: [Autoplay, Pagination],
				autoplay: { delay: 3000 },
				pagination: {
					clickable: true
				},
				lazy: true,
			},
		},
		theme3: {
			container: {
				className: 'rounded w-full h-full aspekper',
				style: {
					borderColor: colors.primary,
					backgroundImage: `linear-gradient(to bottom right, ${backgroundColor.primary}, ${backgroundColor.tertiary})`
				},
			},
			swiperProps: {
				className: 'aspekper rounded-sm w-full h-full cursor-pointer',
				modules: [Autoplay, Pagination],
				autoplay: { delay: 3000 },
				pagination: {
					clickable: true
				},
				lazy: true,
			},
		},
	};

	const theme = themeProps[`theme${themeNumber}`];

	useEffect(() => {
		const sortedBanners = Object.keys(banners).sort();
		const filteredBanners = sortedBanners.filter((d) => banners[d].img !== '');
		const refinedBanners = filteredBanners.map((banner) => ({
			redirect: banners[banner].redirect,
			img: banners[banner].img
		}));

		setBannerList(refinedBanners);
	}, [banners]);

	return (
		<div
			{...theme.container}
		>
			<Swiper
				{...theme.swiperProps}
			>
				{bannerList.map((banner, i) => (
					<SwiperSlide key={banner.img}>
						<Image
							loader={customLoader}
							layout="fill"
							objectFit="cover"
							src={banner.img}
							onClick={() => {
								if (banner.redirect) window.open(banner.redirect, '_blank');
							}}
							alt={'banner-' + (i + 1)}
						/>
					</SwiperSlide>
				))}

				{themeNumber === 2 ? (
					<>
						<SwiperSlide className="flex md:hidden">
							<HighlightPromo
								{...p}
								src="/icons/giftimg.png"
								context={['promotions', appContext.t('text.claimReward')]}
								clickSrc="/promotion"
							/>
						</SwiperSlide>

						<SwiperSlide className="flex md:hidden">
							<LastPlayedGame {...p} lastPlayedGameData={lastPlayedGameData} />
						</SwiperSlide>
					</>
				) : null}
			</Swiper>
		</div>	
	);
}
