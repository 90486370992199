import Link from 'next/link';
import BrandConfigStore from '../../../context/store/brand-config';
import AppContext from '../../../context/app.context/app.context';
import { useContext } from 'react';

const SectionLayout = (p) => {
	const { colors } = BrandConfigStore(state => state.config.themeConfig.container);
	const appContext = useContext(AppContext);
	const { name, children, src, themeNumber } = p;
	const splitName = name?.split(' ');
	const changeCaseName = splitName?.map(
		(name) => name.charAt(0).toUpperCase() + name.slice(1),
	);
	const newName = changeCaseName?.join(' ');

	const themeProps = {
		theme1: {
			container: {
				className: 'w-full mx-auto mb-4 tablet:mb-6 space-y-1 tablet:space-y-2',
			},
			subContainer: {
				className: 'flex justify-between items-center',
				style: { color: colors.primary },
			},
			text: {
				className: 'font-quicksandBold text-base mobile-xl:text-lg xl:text-xl',
			},
			link: {
				className: 'font-quicksandLight text-base mobile-xl:text-lg xl:text-xl',
			},
		},
		theme2: {
			container: {
				className: 'w-full mx-auto mb-4 tablet:mb-6',
			},
			subContainer: {
				className: 'flex justify-between items-center tracking-widest mb-2',
				style: { color: colors.primary },
			},
			text: {
				className: 'font-quicksandBold text-base mobile-xl:text-lg xl:text-xl',
			},
			link: {
				className: 'font-quicksandLight text-base mobile-xl:text-lg xl:text-lg',
			},
		},
		theme3: {
			container: {
				className: 'w-full mx-auto mb-4 tablet:mb-6',
			},
			subContainer: {
				className: 'flex justify-between items-end tracking-widest pb-3',
				style: { color: colors.primary },
			},
			text: {
				className: 'font-quicksandBold text-base mobile-xl:text-lg xl:text-2xl',
			},
			link: {
				className: 'font-normal text-base mobile-xl:text-lg xl:text-xl',
			},
		},
	};
	const needViewAll = newName?.toLowerCase() === appContext.t('text.liveCasino')?.toLowerCase() || newName?.toLowerCase() === appContext.t('text.slots')?.toLowerCase() || newName?.toLowerCase() === appContext.t('text.provider')?.toLowerCase();
	const theme = themeProps[`theme${themeNumber}`];

	return (
		<>
			<div {...theme.container}>
				<div {...theme.subContainer}>
					<p {...theme.text}>
						{newName}
					</p>
					<Link href={src}>
						<a {...theme.link}>
							{needViewAll ? appContext.t('text.viewAll') : null}
						</a>
					</Link>
				</div>
				{children}
			</div>
		</>
	);
};

export default SectionLayout;
