import { useEffect, useContext, useState, Fragment, useRef } from 'react';
import AppContext from '../src/context/app.context/app.context';
import { useRouter } from 'next/router';

import Announcement from '../src/components/molecules/Notification/Announcement';
import PromoBanner from '../src/components/molecules/PromoBanner/PromoBanner';
import HighlightPromo from '../src/components/molecules/PromoBanner/HighlightPromo';
import LastPlayedGame from '../src/components/molecules/PromoBanner/LastPlayedGame';
import SectionLayout from '../src/components/templates/Layout/SectionLayout';
import Popular from '../src/components/molecules/Popular/Popular';
import Recommend from '../src/components/molecules/Popular/Recommend';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
// import JackpotProgress from '../src/components/molecules/ProgressBar/JackpotProgress';
import Game from '../src/components/molecules/Game/Game';
import GameLarge from '../src/components/molecules/Game/GameLarge';
import Logo from '../src/components/molecules/Logo/Logo';
import RealTimeTransactions from '../src/components/molecules/RealTimeTransaction/RealTimeTransactions';
import GlobalStateStore from '../src/context/store/global-state';
import GameServices from '../src/services/api/games/game.services';
import TogelCard from '../src/components2/organism/pages/home/togel-section';
import BrandConfigStore from '../src/context/store/brand-config';
import ModalStore from '../src/context/store/modal-pop-up';
// import { useLanguage } from '../src/hooks/useLanguage';

const Home = (p) => {
	// const { t } = useLanguage();
	const { theme } = p;
	const { token, url, setUrl, headerHeight } = GlobalStateStore((state) => ({
		token: state.token,
		headerHeight: state.headerHeight,
		url: state.url,
		setUrl: state.setUrl
	}));
	const { themeConfig, getBrandSetting2 } = BrandConfigStore((state) => ({
		themeConfig: state.config.themeConfig.container,
		getBrandSetting2: state.getBrandSetting2
	}));
	const { backgroundColor, colors } = themeConfig;
	const setModal = ModalStore((state) => state.setModal);
	const appContext = useContext(AppContext);
	const router = useRouter();
	const sportRef = useRef(null);
	const lotteryRef = useRef(null);
	const cardRef = useRef(null);

	const [totalGames, setTotalGames] = useState({
		lc: 1,
		sl: 1,
		lt: 1,
		cg: 1
	});

	const [lastPlayedInfo, setLastPlayedInfo] = useState({
		show: false,
		data: {}
	});

	const firstGame = {
		data: {
			gpName: 'PragmaticPlay',
			s3ImageUrl: {
				square200x200:
					'https://api.prerelease-env.biz/game_pic/square/200/vs100firehot.png?w=256&q=75'
			},
			gameName: 'fire hot 100',
			title: appContext.t('text.firstGame'),
			gameId: 'PP-vs100firehot'
		}
	};

	useEffect(() => {
		if (token && token !== '') {
			const idToken = token?.split(':')[0];

			if (idToken !== 'sessionToken') {
				GameServices.GetLastPlayedGames().then((lastPlayedGames) => {
					if (lastPlayedGames?.result?.length)
						setLastPlayedInfo({
							...lastPlayedInfo,
							show: true,
							data: lastPlayedGames?.result?.[0]
						});
					else setLastPlayedInfo({ ...lastPlayedInfo, show: false });
				});
			} else {
				setLastPlayedInfo({ ...lastPlayedInfo, show: false });
			}
		}
	}, [token]);

	useEffect(() => {
		if (url.includes('#')) {
			const elm = document.querySelector(url);
			window.scrollTo(0, elm?.getBoundingClientRect().top + window.scrollY - headerHeight);
			setUrl('');
		}
	}, [url]);

	useEffect(() => {
		getBrandSetting2();
		if (router.query.returnUrl !== undefined) setModal({ mode: 'login', type: 'login' });
	}, []);

	const homePageOrder = [
		'announcement',
		'promo',
		'popular',
		'recommended',
		'casino',
		'jackpot',
		'slots',
		'lottery',
		'card game',
		'sports',
		'winning numbers',
		'providers',
		'transactions'
	];

	p = {
		...p,
		themeNumber: theme,
		setTotalGames
	};

	const sectionOrder = () => {
		const finalOrder = [];
		homePageOrder.map((elKey) => {
			switch (elKey) {
				case 'announcement':
					finalOrder.push(
						<Fragment key={elKey}>
							<Announcement {...p} />
						</Fragment>
					);
					break;
				case 'promo':
					finalOrder.push(
						<div key={elKey} className="grid grid-cols-12 gap-4 my-3 sm:my-6">
							<div className={`col-span-12 ${theme !== 3 && 'lg:col-span-8 xl:col-span-9'}`}>
								<PromoBanner
									lastPlayedGameData={firstGame}
									lastPlayedInfo={lastPlayedInfo.show}
									{...p}
								/>
							</div>

							<div
								className={`col-span-12 w-full gap-4 ${
									theme === 2 && 'hidden sm:space-y-0 md:flex'
								} ${
									theme !== 3
										? `lg:col-span-4 xl:col-span-3 space-y-3 sm:space-y-0 ${
											theme === 2 ? 'lg:flex' : 'sm:flex'
										} lg:flex-col`
										: 'h-full flex'
								}`}>
								<HighlightPromo
									{...p}
									src="/icons/giftimg.png"
									context={['promotions', appContext.t('text.claimReward')]}
									clickSrc="/promotion"
								/>
								{!lastPlayedInfo.show ? (
									<LastPlayedGame {...p} lastPlayedGameData={firstGame} />
								) : (
									<LastPlayedGame {...p} lastPlayedGameData={lastPlayedInfo} />
								)}
							</div>
						</div>
					);
					break;
				case 'recommended':
					finalOrder.push(
						(!token || token?.split(':')[0] === 'sessionToken') && (
							<div key={elKey} className="my-4 sm:my-6">
								<SectionLayout name={appContext.t('text.recommendedGames')} src={''} {...p}>
									<Recommend {...p} />
								</SectionLayout>
							</div>
						)
					);
					break;
				case 'popular':
					finalOrder.push(
						token && token?.split(':')[0] !== 'sessionToken' && (
							<div key={elKey} className="my-4 sm:my-6">
								<SectionLayout name={appContext.t('text.popularGames')} src={''} {...p}>
									<Popular {...p} />
								</SectionLayout>
							</div>
						)
					);
					break;
				case 'casino':
					totalGames.lc > 0 &&
						finalOrder.push(
							<SectionLayout
								key={elKey}
								name={appContext.t('text.liveCasino')}
								src="/games/casino"
								{...p}>
								{/* <Game  {...p} category="casino" /> */}
								<Game {...p} category="lc" />
							</SectionLayout>
						);
					break;
				// case 'jackpot':
				// 	finalOrder.push(
				// 		<div key={elKey} className="my-4 sm:my-6">
				// 			<JackpotProgress  {...p} />
				// 		</div>
				// 	);
				// 	break;
				case 'slots':
					totalGames.sl > 0 &&
						finalOrder.push(
							<SectionLayout
								key={elKey}
								name={appContext.t('text.slots')}
								src="/games/slots"
								{...p}>
								<Game {...p} category="sl" />
							</SectionLayout>
						);
					break;
				case 'lottery':
					totalGames.lt > 0 &&
						finalOrder.push(
							<div id="lottery" ref={lotteryRef} key={elKey}>
								<SectionLayout name={appContext.t('text.lottery')} src="/games/lottery" {...p}>
									<Game {...p} category="lt" />
								</SectionLayout>
							</div>
						);
					break;
				case 'card game':
					totalGames.cg > 0 &&
						finalOrder.push(
							<div id="card-game" ref={cardRef} key={elKey}>
								<SectionLayout name={appContext.t('text.cardGame')} src="/games/card_game" {...p}>
									<Game {...p} category="cg" />
								</SectionLayout>
							</div>
						);
					break;
				case 'sports':
					finalOrder.push(
						<div id="sports" key={elKey} ref={sportRef}>
							<GameLarge {...p} src="/" />
						</div>
					);
					break;
				case 'winning numbers':
					finalOrder.push(
						<div id="winning-numbers" key={elKey}>
							<SectionLayout name={appContext.t('label.winningNumbers')} src="#" {...p}>
								<button
									onClick={() => {
										if (!token || token.split(':')[0].toLowerCase() === 'sessiontoken') {
											setModal({ type: 'login', mode: 'login' });
										} else {
											setModal({ type: 'HKB-1', mode: 'topup' });
										}
									}}
									className="btn w-full mb-4 rounded-sm lg:hidden"
									style={{ backgroundColor: backgroundColor.accent2, color: colors.primary }}>
									{appContext.t('btn.placeNumbers')}
								</button>
								<TogelCard {...p} />
							</SectionLayout>
						</div>
					);
					break;
				case 'providers':
					finalOrder.push(
						<SectionLayout key={elKey} name={appContext.t('text.provider')} src="/providers" {...p}>
							<Logo {...p} />
						</SectionLayout>
					);
					break;
				case 'transactions':
					finalOrder.push(
						<div key={elKey} className="mx-auto w-full my-4 sm:my-8">
							<RealTimeTransactions {...p} />
						</div>
					);
					break;
			}
		});
		return finalOrder;
	};

	const ordered = sectionOrder();

	return <div className={'z-0'}>{ordered}</div>;
};

export default Home;
