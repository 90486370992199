import Image from 'next/image';
import customLoader from '../../../helpers/custom-image-loader';
import BrandConfigStore from '../../../context/store/brand-config';
import GlobalStateStore from '../../../context/store/global-state';
import ModalStore from '../../../context/store/modal-pop-up';

export default function GameThumbnailRect(p) {
	const { backgroundColor, colors } = BrandConfigStore(state => state.config.themeConfig.container);
	const { token } = GlobalStateStore(state => ({ token: state.token }));
	const { setModal } = ModalStore(state => ({ setModal: state.setModal }));
	const { data, themeNumber } = p;

	const onButtonClickHandler = async () => {
		if (token && token.split(':')[0].toLowerCase() !== 'sessiontoken') {
			try {
				window.open(data.url);
			} catch (error) {
				console.log(error);
			}
		} else {
			setModal({ type: 'login', mode: 'login' });
		}
	};

	const themeProps = {
		theme1: {
			container: {
				onClick: onButtonClickHandler,
				className: 'w-full flex sportAspect overflow-hidden rounded-lg relative p-4',
				style: {
					backgroundImage: `linear-gradient(to bottom right, ${backgroundColor.primary}, ${backgroundColor.accent2})`,
					color: colors.primary,
				},
			},
			imageContainer: {
				className: 'aspect-square absolute top-0 mobile-xl:right-2 w-full mobile-xl:w-1/2 sm:w-2/3 xl:w-1/3 opacity-20 brightness-50 xl:opacity-100 xl xl:brightness-100 xl:top-3',
			},
			image: {
				src: data.imageUrl,
				loader: customLoader,
				objectFit: 'cover',
				layout: 'fill',
				className: 'object-top',
			},
			textContainer: {
				className: 'self-center gap-2 z-10 py-2 sm:py-4',
			},
			textOne: {
				className: 'font-quicksandBold  text-5xl mobile-s:text-6xl mobile-xl:text-7xl sm:text-5xl md:text-6xl lg:text-7xl tracking-tight uppercase',
			},
			textTwo: {
				className: 'font-quicksandLight mt-px leading-6 text-xl mobile-l:text-2xl mobile-xl:text-2xl sm:text-xl md:text-2xl w-full xl:w-2/3',
			},
		},
		theme2: {
			container: {
				onClick: onButtonClickHandler,
				className: 'w-full flex justify-center items-center sportAspect overflow-hidden relative p-4 border shadowCard rounded-tr-3xl rounded-bl-3xl',
				style: {
					backgroundImage: `linear-gradient(to bottom right, ${backgroundColor.primary}, ${backgroundColor.tertiary})`,
					color: colors.primary,
				},
			},
			imageContainer: {
				className: 'aspect-square absolute top-0 mobile-xl:right-2 w-full mobile-xl:w-1/2 sm:w-2/3 xl:w-1/3 opacity-20 brightness-50 xl:top-3',
			},
			image: {
				src: data.imageUrl,
				loader: customLoader,
				objectFit: 'cover',
				layout: 'fill',
				className: 'object-top',
			},
			textContainer: {
				className: 'gap-4 z-10 py-2 sm:py-4 flex-col lg:flex-row flex justify-center',
			},
			textOne: {
				className: 'font-quicksandBold  text-5xl mobile-s:text-6xl mobile-xl:text-7xl sm:text-5xl md:text-6xl lg:text-7xl tracking-tight uppercase',
			},
			textTwo: {
				className: 'font-quicksandLight mt-px leading-6 text-xl mobile-l:text-2xl mobile-xl:text-2xl sm:text-xl md:text-2xl w-full',
			},
		},
		theme3: {
			container: {
				onClick: onButtonClickHandler,
				className: 'w-full grid grid-cols-3 aspect-[3/1] gap-4 relative rounded-sm px-4',
				style: {
					background: backgroundColor.tertiary,
					color: colors.primary,
				},
			},
			imageContainer: {
				className: 'top-0 xl:w-full h-full left-1/3 xl:left-0  w-3/5 col-span-1 absolute xl:relative opacity-20 xl:opacity-100',
			},
			subImageContainer: {
				className: 'flex relative w-full h-full justify-center',
			},
			backgroundContainer: {
				className: 'w-1/2 h-full absolute',
				style: {
					backgroundImage: `linear-gradient(to bottom, ${backgroundColor.accent2}, ${backgroundColor.tertiary})`
				},
			},
			image: {
				src: data.imageUrl,
				loader: customLoader,
				objectFit: 'contain',
				layout: 'fill',
				className: 'object-bottom',
			},
			textContainer: {
				className: 'z-10 p-2 mobile-xl:p-4 xl:p-6 flex flex-col justify-center col-span-3 xl:col-span-2',
			},
			textOne: {
				className: 'font-quicksandRegular  text-5xl mobile-s:text-6xl mobile-xl:text-7xl sm:text-5xl md:text-6xl lg:text-8xl tracking-tight uppercase',
			},
			textTwo: {
				className: 'font-quicksandLight mt-px  leading-6 text-xl mobile-l:text-2xl mobile-xl:text-2xl sm:text-xl md:text-lg w-full',
			},
		},
	};

	const theme = themeProps[`theme${themeNumber}`];

	return (
		<div {...theme.container}>
			<div {...theme.imageContainer}>
				{themeNumber === 3 ? (
					<div {...theme.subImageContainer}>
						<div {...theme.backgroundContainer}></div>
						<Image {...theme.image}/>
					</div>
				) : (
					<Image {...theme.image}/>
				)}
			</div>
			<div {...theme.textContainer}>
				<p {...theme.textOne}>{data.title}</p>
				<p {...theme.textTwo}>{data.summary}</p>
			</div>
		</div>
	);
}
