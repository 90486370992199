import GameThumbnail from '../GameThumbnail/GameThumbnail';
import GameStore from '../../../context/store/game-state';
import { useEffect } from 'react';
import GlobalStateStore from '../../../context/store/global-state';

function Popular(p) {
	const { themeNumber } = p;
	const { popularGames, getPopularGame } = GameStore((state) => state);
	const { token } = GlobalStateStore((state) => state);

	useEffect(() => {
		if (token && token !== '') {
			getPopularGame();
		}
	}, [token]);

	return (
		<div className="overflow-hidden">
			<div className="overflow-x-scroll scrollbar-hide">
				<div className={`grid gap-4 home-game-section${themeNumber}`}>
					{!popularGames.length
						? Array.from({ length: 10 }).map((_, i) => (
							<div key={i} className="h-40 w-full bg-gray-500/40 animate-pulse col-span-1" />
						))
						: popularGames.map((game) => {
							return <GameThumbnail key={game.gameName} themeNumber={themeNumber} game={game} />;
						})}
				</div>
			</div>
		</div>
	);
}

export default Popular;
