import { useEffect, useState } from 'react';
import BrandConfigStore from '../../../context/store/brand-config';

export default function Announcement(p) {
	const { brandColor: { backgroundColor, colors }, isLoadingConfig } = BrandConfigStore(state => ({ brandColor: state.config.themeConfig.container, isLoadingConfig: state.isLoadingConfig }));
	
	const { brandConfig, themeNumber } = p;
	const [announcement, setAnnouncement] = useState('');

	const themeProps = {
		theme1: {
			cardContainer: {
				className: 'flex w-full items-center px-6 py-2 rounded-lg',
				style: {
					backgroundColor: backgroundColor.secondary
				}
			},
			cardContent: {
				className: 'truncate w-full text-clip overflow-hidden font-quicksandLight font-light laptop:font-quicksandRegular text-xs tablet:text-base',
				style: {
					color : colors.primary
				}
			}
		},
		theme2: {
			cardContainer: {
				className: 'flex w-full items-center px-4 py-2 rounded-sm border shadowCard rounded-tr-3xl rounded-bl-3x',
				style: {
					borderColor: colors.primary,
					backgroundColor: backgroundColor.tertiary
				}
			},
			cardContent: {
				className: 'truncate w-full text-clip overflow-hidden font-quicksandLight font-light laptop:font-quicksandRegular text-xs tablet:text-base',
				style: {
					color : colors.primary
				}
			}
		},
		theme3: {
			cardContainer: {
				className: 'flex w-full items-center py-2 rounded',
				style: {
					backgroundColor: backgroundColor.tertiary
				}
			},
			cardContent: {
				className: 'truncate w-full text-clip overflow-hidden font-quicksandLight font-light laptop:font-quicksandRegular text-xs tablet:text-base',
				style: {
					color : colors.primary
				}
			}
		},
	};

	const theme = themeProps[`theme${themeNumber}`];

	useEffect(() => {
		if (!isLoadingConfig) {
			const lang = localStorage.getItem('lang');
			Object.keys(brandConfig.announcement).map(key => {
				if (brandConfig.announcement[key].langCode === lang) {
					if (!brandConfig.announcement[key].isDisplay) return;
					else {
						setAnnouncement(brandConfig.announcement[key].content);
					}
				}
			});
		}
	}, [isLoadingConfig]);

	if (!announcement) return <></>;

	return (
		<div className="z-40 w-full mx-auto my-4 sm:my-6">
			<div
				className={theme.cardContainer.className}
				style={theme.cardContainer.style}
			>
				<div className={theme.cardContent.className}
					style={theme.cardContent.style}
				>
					{/* <Marquee loop={0} gradient={false} delay={1}>
						{announcement}
					</Marquee> */}
					<p className='animate-marquee'>{announcement}</p>
				</div>
			</div>
		</div>
	);
}
