import { createWithEqualityFn } from 'zustand/traditional';
import GameServices from '../../services/api/games/game.services';
import getToken from '../../services/getToken';

const GameStore = createWithEqualityFn((set) => ({
	popularGames: [],
	isLoading: true,
	getPopularGame: () => {
		const token = getToken();
		if (token) {
			GameServices.GetPopularGames().then((response) => {
				set((state) => ({ ...state, popularGames: response, isLoading: false }));
			});
		}
	}
}));

export default GameStore;
