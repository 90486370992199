import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(LocalizedFormat);

export const formatDate = (date, format, utc) => {
	if (!format) return dayjs(date);
	
	if (utc) {
		return dayjs.utc(date).format(format);
	} else {
		return dayjs(date).format(format);
	}
};

export const getTimeZone = () => {
	return dayjs().utcOffset() / 60;
};

